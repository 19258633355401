header {
  position: sticky;
  top: 0;
  z-index: 999;

  .top-header {
    background: var(--primary-color);

    &__right-text {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      align-items: center;

      p {
        margin-bottom: 0;
        color: #333;
      }
    }
  }

  .primary-header {
    padding: 1rem 0;
    background: white;

    .navbar {
      padding: 0;

      .navbar-brand {
        img {
          @media only screen and (max-width: 767px) {
            max-width: 200px;
            margin-left: 0.5rem;
          }
        }
      }
      .navbar-toggler {
        margin-right: 1rem;
      }

      #basic-navbar-nav {
        @media only screen and (max-width: 767px) {
          // background: rgb(243, 243, 243);
          // position: absolute;
          width: 100%;
          z-index: 9999;
          top: 60px;
        }

        .navbar-nav {
          padding-left: 1rem;

          .nav-link {
            color: #000;
            font-size: 0.8125rem;
            font-weight: 600;
            // text-align: center;
            // padding-right: 1rem;

            @media only screen and (max-width: 767px) {
              border-bottom: 1px solid #e6e6e6;
              padding: 1rem;
            }
          }
        }

        &-buttons {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 10px;
          margin-left: 1rem;

          @media only screen and (max-width: 1199px) {
            flex-direction: column;
          }

          @media only screen and (max-width: 991px) {
            margin-top: 1px;
          }

          @media only screen and (max-width: 767px) {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 2rem;
          }

          button,
          a {
            font-weight: 600;
          }
        }
      }
    }
  }
}
