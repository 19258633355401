.business-quote {
  @media only screen and (max-width: 992px) {
    .container-lg {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }

  // .business-quote-outer {
  //   // background: var(--primary-color);
  //   // padding: 3rem;
  //   width: 1040px;
  //   margin: 0 auto;

  //   @media only screen and (max-width: 767px) {
  //     width: auto;
  //   }
  // }
}
