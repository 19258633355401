.quotes-card {
  width: 100%;
}

img.quote-logo {
  max-width: 4rem;
  max-height: 100%;
}

.parcel-shop-btn-container button {
  text-transform: unset;
}
