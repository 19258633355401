*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

:root {
  /* --secondary-color: #eaeaea;
  --white: #fff; */
  --primary-color: #fdcd2e;
  --black: #151206;
  --white: #f6f7f8;
  --dark: #283f62;
  --light: #849ca8;
  --red: #c25876;
}

body {
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .container {
  width: 1200px;
  margin: 0 auto;
} */

#alert-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2000px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.payment-modal {
  width: 400px;
  position: fixed;
  overflow: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 5px;
  background-color: #e6ebf2;
  text-align: center;
  color: #93a7b2;
  text-align: center;
  z-index: 11;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
}

.payment-modal>form {
  width: 100%;
  padding: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-details-wrapper>img {
  width: 100%;
  vertical-align: top;
}

#submit-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#submit-container>button {
  line-height: normal;
  cursor: pointer;
  border: 0px solid transparent;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  width: 100%;
  float: left;
  padding: 1rem 0;
  text-align: center;
  color: #fff;
  background: #66ad5b;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

#submit-container>button.cancel {
  cursor: pointer;
  border: 0px solid transparent;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  width: 100%;
  float: left;
  padding: 1rem 0;
  text-align: center;
  color: #fff;
  background: #e84118;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 480px) {

  .payment-modal {
    width: 280px;
  }

  .Toastify__toast-container {
    width: 80vw;
    left: unset;
  }

  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    transform: translateX(0);
  }

}